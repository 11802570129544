import React, { useEffect, useState } from "react";
import User from "../../helpers/login/userData";
import { Helmet } from "react-helmet";
import Config from "../../config";
/**
 *
 * @Components props.data
 * name: component name (not user for rendering)
 * notes : copyright info,
 * img: company logo,
 * items[]: footerlinks contains array of {url, name}
 */

const Chats = (props) => {
  console.log("chat props", props);
  const user = User();
  //checking for twilo chat file v1
  const Twilio = window["Twilio"];
  const MoveoAI = window["MoveoAI"];
  const instance = window["instance"];
  const twilioChatWorkerApi = Config.twilioChatWorkerApi;
  const [mode, setMode] = useState("dev");
  //checking version of call center
  const callCenter = JSON.parse(
    window.localStorage.getItem("staticCallCenter")
  );
  const projectName = window.localStorage.getItem("staticProjectName")
    ? window.localStorage.getItem("staticProjectName").replaceAll('"', "")
    : "retail";
  const projectDesc = window.localStorage.getItem("staticProjectDesc")
    ? window.localStorage.getItem("staticProjectDesc").replaceAll('"', "")
    : "DesignLook";

  if (user?.userid != null) {
    window.analytics.identify(user.userid, {
      name: user.fullname,
      email: user?.email,
    });
  }

  //DEV  and PROD use different instances of AWS so need different IDs
  const snippetIDprod =
    "QVFJREFIaWFZYXRVSlpIekdkUUg5YXhZenVQMktKRXNIWTVFQWpBYVErTEdzRnpvZHdGdk9FUUNxVTFuSzYvcHhOaTJ0RWc5QUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNUkt3ZWpDcS9JV2hmNlJtdkFnRVFnQ3ZQM3hvdHdmRE1PUllDcmdwNlY2TlN5SFZEeitERThQcys5alBsdEpvRWk1b3JLMURMOHA3SDczVnc6OkhkU01SWTlOVkIrQjR5Qm5OWUQwNHI4eHVRZGJFUTBKNkdKOXdFUllKMlZWcElsN3ppZmhrQklPa1VTQTh3UnVleGJkSXVIdll6Q212M0dKY012Sk8vanFpQVFzbEdyZTRUNTZnYkxRa0s3OHpyeUNNdjZaNEJaZUc2aWdteXAxakFWbTk4TlNzZmh1Y1JDQ0h3UVhTREdpWGw0TlpWTT0";
  const connectIDprod = "e16f5500-d3c8-4689-bf36-3bcb86025b7e";
  const authenticateProd =
    "https://pkkwb3ebj7ftlun22d5hr6hcji0gjbkt.lambda-url.us-east-1.on.aws";
  const snippetIDdev =
    "QVFJREFIakZhMVo2ZGZmSXpGSnpJS2lYakthYVBxMmJIU0ZPbnhET3AyalJDV1F3UWdHRVE0SUJjeWZySEZxRHM5T0VBNkNTQUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNR2lmcVlzNkFFSmtnY1RJZUFnRVFnQ3VTa0Y2OTRhZ1FZc1V2bEVFajFWR3U2dmIvMXNqUVR5am5mWEdrdFNCUWlzbC9yeHNZcDhBMGVBUG46OkxucnVXZ2RkMkVIWG5aU1RaeDlpNE5rUGo4OHVxWmorMExVSEZwbE9jRHRTcjYyd01DTXRML2VtNlJXa3JHTjVyWWhqV3ZsN0ZPSTBacm1GRCtDcmdIdjNRdkU5bldjWkc2UkJycWZ3d1A4OHFYL1BYU29XMERDKyt4MDJtbE9JUGRFN001bjZmZFRNT0p3WVFqT3g3aDJnVUtQa25wQT0";
  const connectIDdev = "69d5653f-e608-4c55-8c59-755ece2bdec6";
  const authenticateDev =
    "https://pj6pjtxzw5yu4brhvthp6c4l340xljdv.lambda-url.us-east-1.on.aws";

  if (callCenter === "amz") {
    //get URL if production url switch mode for chat to load correspondent instance IDs
    console.log(
      "DOMAIN",
      window.location.origin.replace("http://", "").replace("https://", "")
    );
  }

  useEffect(() => {
    console.log("AWSC AWSsnippetID", Config.AWSsnippetID);
    console.log("AWSC AWSconnectID", Config.AWSconnectID);
    console.log("AWSC AWSAuthorizeURL", Config.AWSAuthorizeURL);
    if (user?.email && callCenter === "twl") {
      const timeout = setTimeout(() => {
        webChatStart();
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [callCenter, user]);

  function isLoadedScript(functionName, callback) {
    window[functionName] = callback;
  }

  const moveoInit = () => {
    if (user?.email && callCenter === "mov") {
      console.log("Moveo Init started");
      MoveoAI?.init({
        integrationId: Config.moveoIntegratedId,
        element: document.getElementById("embed"),
        bubble_image:
          "https://agentx-demo-staticweb-dev.hgsdigital.com/images/chat/moveo.png",
      })
        .then((instance) => {
          instance?.onSessionCreated(() => {
            instance.updateContext({
              user: { email: user?.email },
              vertical: projectDesc,
            });
          });
          console.log("Moveo Connected " + projectName + " " + projectDesc);
          instance?.setCSSVariables({
            "--moveo-background-color": props?.site?.moveobackgroundcolor,
            "--moveo-background-color-hover":
              props?.site?.moveobackgroundcolorhover,
            "--moveo-review-number": props?.site?.moveoreviewnumber,
            "--moveo-accent-color": props?.site?.moveoaccentcolor,
            "--moveo-text-05": props?.site?.moveotext05,
            "--moveo-text-04": props?.site?.moveotext04,
            "--moveo-text-03": props?.site?.moveotext03,
            "--moveo-text-02": props?.site?.moveotext02,
            "--moveo-text-01": props?.site?.moveotext01,
            "--moveo-ui-background-02": props?.site?.moveouibackground02,
            "--moveo-ui-background-01": props?.site?.moveouibackground01,
          });
        })

        .catch((error) => console.error(error));
    }
    if (user?.email && callCenter === "movprod") {
      console.log("Moveo Init PROD  started");
      MoveoAI?.init({
        integrationId: Config.moveoIntegratedIdProd,
        host: Config.moveoHostProd,
        element: document.getElementById("embed"),
        bubble_image:
          "https://agentx-demo-staticweb-dev.hgsdigital.com/images/chat/moveoprod.png",
      })
        .then((instance) => {
          instance?.onSessionCreated(() => {
            instance.updateContext({
              user: { email: user?.email, display_name: user?.fullname },
              vertical: projectDesc,
            });
          });
          //console.log("Moveo Connected " + projectName + " " + projectDesc);
          instance?.setCSSVariables({
            "--moveo-background-color": props?.site?.moveobackgroundcolor,
            "--moveo-background-color-hover":
              props?.site?.moveobackgroundcolorhover,
            "--moveo-review-number": props?.site?.moveoreviewnumber,
            "--moveo-accent-color": props?.site?.moveoaccentcolor,
            "--moveo-text-05": props?.site?.moveotext05,
            "--moveo-text-04": props?.site?.moveotext04,
            "--moveo-text-03": props?.site?.moveotext03,
            "--moveo-text-02": props?.site?.moveotext02,
            "--moveo-text-01": props?.site?.moveotext01,
            "--moveo-ui-background-02": props?.site?.moveouibackground02,
            "--moveo-ui-background-01": props?.site?.moveouibackground01,
          });
        })

        .catch((error) => console.error(error));
    }
  };
  useEffect(() => {
    moveoInit();
  }, [window.MoveoAI]);

  /*WEBCHAT INIT*/
  const webChatStart = () => {
    //checking available agent and trigger the chat
    //let agent = false;
    if (twilioChatWorkerApi) {
      const fetchPromise = fetch(twilioChatWorkerApi);

      fetchPromise
        .then((response) => {
          return response.json();
        })
        .then((results) => {
          //if there are messages it will start Chat Session with TWILIO CHAT =>  meaning there will be available agents online
          if (results.message && !results.error) {
            const appConfig = {
              accountSid: results.AI,
              flexFlowSid: results.FL,
              context: {
                friendlyName: user?.fullname
                  ? user.fullname
                  : "Website Visitor",
                locationOrigin: window.location.origin,
                verticals: projectName,
              },
              componentProps: {
                MessagingCanvas: {
                  // predefinedMessage: false,
                  // showWelcomeMessage: true,
                  memberDisplayOptions: {
                    // yourDefaultName: "You",
                    // yourFriendlyNameOverride: false,
                    theirDefaultName: "Agent",
                    theirFriendlyNameOverride: false,
                  },
                },
              },
              startEngagementOnInit: false,
              preEngagementConfig: {
                description:
                  "Please provide some information about yourself to know how to address you in conversation",
                fields: [
                  {
                    label: "Enter your email to start conversation?",
                    type: "InputItem",
                    attributes: {
                      name: "Website_Visitor",
                      type: "email",
                      value: user?.email ? user?.email : "",
                      required: true,
                    },
                  },
                ],
                submitLabel: "Start conversation",
              },
            };
            Twilio.FlexWebChat.EntryPoint.defaultProps.tagline = "LIVE CHAT";
            Twilio.FlexWebChat.MainHeader.defaultProps.showImage = true;
            Twilio.FlexWebChat.MainHeader.defaultProps.titleText = "LIVE";
            Twilio.FlexWebChat.MainHeader.defaultProps.imageUrl = props.site
              ?.logo
              ? props.site.logo
              : "/images/" + projectName + "/logo.png";
            Twilio.FlexWebChat.MessagingCanvas.defaultProps.showWelcomeMessage = true;
            Twilio.FlexWebChat.MessageListItem.defaultProps.useFriendlyName = true;
            Twilio.FlexWebChat.MessageListItem.defaultProps.displayName = true;
            Twilio.FlexWebChat.MessagingCanvas.defaultProps.predefinedMessage = false;
            Twilio.FlexWebChat.renderWebChat(appConfig);

            // }
          } else {
            console.log(
              "THERE ARE NO AVAILABLE AGENTS for communication, skipping chat..."
            );
          }
        })
        .catch((error) =>
          console.log("THERE IS AN ERROR in checking on available workers", {
            error,
          })
        );
    } else {
      alert("twilioChatWorkerApi", twilioChatWorkerApi);
    }
  };

  // CHATS will return available Chat according to selection of callCentar in  SWITCH module by injecting required <script>
  return (
    <>
      <Helmet>
        <script
          id="moveoScript"
          crossOrigin=""
          rel="preload"
          src="https://cdn.jsdelivr.net/npm/@moveo-ai/web-client@latest/dist/web-client.min.js"
        ></script>
      </Helmet>

      {user?.email && callCenter === "amz" && (
        <Helmet>
          <script type="text/javascript">
            {`(function(w, d, x, id){
    s=d.createElement('script');
    s.src='https://dtn7rvxwwlhud.cloudfront.net/amazon-connect-chat-interface-client.js';
    s.async=1;
    s.id=id;
    d.getElementsByTagName('head')[0].appendChild(s);
    w[x] =  w[x] || function() { (w[x].ac = w[x].ac || []).push(arguments) };
  })(window, document, 'amazon_connect', '${
    window.location.origin.replace("http://", "").replace("https://", "") ===
    "agentx-demo-staticweb.hgsdigital.com"
      ? connectIDprod
      : connectIDdev
  }');
  amazon_connect('styles', { iconType: 'CHAT', openChat: { color:'${
    props?.site?.chatcolor
  }' , backgroundColor: '${
              props?.site?.chatbackground
            }' }, closeChat: { color:'${
              props?.site?.chatcolor
            }' , backgroundColor: '${props?.site?.chatbackground}' } });
  amazon_connect('snippetId', '${
    window.location.origin.replace("http://", "").replace("https://", "") ===
    "agentx-demo-staticweb.hgsdigital.com"
      ? snippetIDprod
      : snippetIDdev
  }=');
  amazon_connect('supportedMessagingContentTypes', [ 'text/plain', 'text/markdown', 'application/vnd.amazonaws.connect.message.interactive', 'application/vnd.amazonaws.connect.message.interactive.response' ]);
  amazon_connect('authenticate', function(callback) {
  window.fetch('${
    window.location.origin.replace("http://", "").replace("https://", "") ===
    "agentx-demo-staticweb.hgsdigital.com"
      ? authenticateProd
      : authenticateDev
  }?customer=${user?.email}').then(res => {
    res.json().then(data => {
      console.log(data.data);
      callback(data.data);
    });
  });
});


amazon_connect('customerDisplayName', function(callback) {
  const displayName = '${user?.fullname}';
  callback(displayName);
});
 
    `}
          </script>
        </Helmet>
      )}
      {user?.email && callCenter === "twl" && (
        <script
          src="https://assets.flex.twilio.com/releases/flex-webchat-ui/2.9.1/twilio-flex-webchat.min.js"
          integrity="sha512-yBmOHVWuWT6HOjfgPYkFe70bboby/BTj9TGHXTlEatWnYkW5fFezXqW9ZgNtuRUqHWrzNXVsqu6cKm3Y04kHMA=="
          crossOrigin=""
        ></script>
      )}

      <div id="embed"></div>
    </>
  );
};
export default Chats;
