/**
 *  @file config.js
 *  @brief Brief
 */
import { envVars } from "./constants/env.constants";
class Config {
  //process.env.NODE_ENV // this is a process to pick from node so we can pass environment variable to application // if we need to  TBD

  constructor() {
    if (window.location.origin === "http://localhost:3000") {
      this.twilioChatWorkerApi =
        "https://agentx-core-service-4137.twil.io/workersOnline?client=agentx-demo-staticweb-dev.hgsdigital.com";
    } else {
      this.twilioChatWorkerApi =
        "https://agentx-core-service-4137.twil.io/workersOnline?client=" +
        window.location.origin.replace("http://", "").replace("https://", "");
    }

    this.callCenter = JSON.parse(
      window.localStorage.getItem("staticCallCenter")
    )
      ? JSON.parse(window.localStorage.getItem("staticCallCenter"))
      : "twl"; //set Twilio by default
    this.project = JSON.parse(window.localStorage.getItem("staticProjectName"))
      ? JSON.parse(window.localStorage.getItem("staticProjectName"))
      : "retail"; //as default will load retail for RPA actions on retail if staticProjectName is not set; finance/retail/automoto/whatever.../
    this.default = `/json/${this.project}/site.config.json`;
    this.paypal = { clientId: "test" };
    // this.GET_LOGIN_API = `https://r6tnuzohd8.execute-api.us-east-1.amazonaws.com/dev/users`;
    this.GET_LOGIN_API = `https://agentx-demo-dev.hgsdigital.com/api/customer360/v1/segment/getUserDetails`;
    this.GET_USERUPDATE_API = `https://agentx-demo-dev.hgsdigital.com/api/demo/finance/`;
    this.GET_STATEMENTS_API = `https://agentx-demo-dev.hgsdigital.com/api/demo/finance/statements`;
    this.GET_SENDEMAIL_API = `https://agentx-demo-dev.hgsdigital.com/api/demo/finance/statements`;
    this.blogsApi = `/json/${this.project}/response/blogs.json`;
    this.promotionsApi = `/json/${this.project}/response/promotions.json`;
    this.productsApi = `/json/${this.project}/response/products.json`;
    this.myAccountApi = `/json/${this.project}/response/user.json`;

    this.readOutApi =
      "https://agentx-demo-prod.hgsdigital.com/api/core/disclosures/send-for-playback";
    // QA : "https://agentx-amex-playback-qa.hgsdigital.com/api/core/disclosures/send-for-playback";
    // "https://agentx-demo-prod.hgsdigital.com/api/core/disclosures/send-for-playback";
    //"https://agentx-demo-prod.hgsdigital.com/api/playback/v1/disclosures/send-for-playback";
    //SET FOR AMAZON CONNECT CHAT
    this.contactFlowId = "066e1dcf-57ef-43b8-8591-c85464161940"; //Connect Demo //
    this.instanceId = "0a52341e-717b-44d6-bcdd-7e17db2cab26"; //Connect Demo
    this.apiGatewayEndpoint = `https://0yprvh2zqj.execute-api.us-east-1.amazonaws.com/Prod`; //Connect Demo
    this.moveoIntegratedId =
      this.callCenter === "mov"
        ? "02ca8301-1a23-4fbd-967c-d80efe25b83e"
        : "e6ed7446-5268-424a-920e-a6cfd6d1093f"; //Moveo brain ID Paw Friends
    this.moveoIntegratedId = "02ca8301-1a23-4fbd-967c-d80efe25b83e"; //Moveo brain ID Paw Friends
    this.moveoIntegratedIdProd = "ca0a0d3c-1041-4319-864e-ce29ff1c3fb1";
    this.moveoHostProd = "https://channels-ws.dev.moveo.ai";

    //this.instanceId = "9f27b523-8f3c-4282-9c59-540faa6f0a6c"; //ccas
    //this.apiGatewayEndpoint ="https://44xzd4yzg6.execute-api.us-east-1.amazonaws.com/Prod";//ccas
    /* AWS DEMO PROD DEV VAR */
    console.log("REACT_APP_ENVIRONMENT", process.env.REACT_APP_ENVIRONMENT);
    if (process.env.REACT_APP_ENVIRONMENT === "prod") {
      this.AWSsnippetID =
        "QVFJREFIaWFZYXRVSlpIekdkUUg5YXhZenVQMktKRXNIWTVFQWpBYVErTEdzRnpvZHdGdk9FUUNxVTFuSzYvcHhOaTJ0RWc5QUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNUkt3ZWpDcS9JV2hmNlJtdkFnRVFnQ3ZQM3hvdHdmRE1PUllDcmdwNlY2TlN5SFZEeitERThQcys5alBsdEpvRWk1b3JLMURMOHA3SDczVnc6OkhkU01SWTlOVkIrQjR5Qm5OWUQwNHI4eHVRZGJFUTBKNkdKOXdFUllKMlZWcElsN3ppZmhrQklPa1VTQTh3UnVleGJkSXVIdll6Q212M0dKY012Sk8vanFpQVFzbEdyZTRUNTZnYkxRa0s3OHpyeUNNdjZaNEJaZUc2aWdteXAxakFWbTk4TlNzZmh1Y1JDQ0h3UVhTREdpWGw0TlpWTT0";
      this.AWSconnectID = "e16f5500-d3c8-4689-bf36-3bcb86025b7e";
      this.AWSAuthorizeURL =
        "https://pkkwb3ebj7ftlun22d5hr6hcji0gjbkt.lambda-url.us-east-1.on.aws";
    } else {
      //for all others DEV instance snippet
      this.AWSsnippetID =
        "QVFJREFIakZhMVo2ZGZmSXpGSnpJS2lYakthYVBxMmJIU0ZPbnhET3AyalJDV1F3UWdHRVE0SUJjeWZySEZxRHM5T0VBNkNTQUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNR2lmcVlzNkFFSmtnY1RJZUFnRVFnQ3VTa0Y2OTRhZ1FZc1V2bEVFajFWR3U2dmIvMXNqUVR5am5mWEdrdFNCUWlzbC9yeHNZcDhBMGVBUG46OkxucnVXZ2RkMkVIWG5aU1RaeDlpNE5rUGo4OHVxWmorMExVSEZwbE9jRHRTcjYyd01DTXRML2VtNlJXa3JHTjVyWWhqV3ZsN0ZPSTBacm1GRCtDcmdIdjNRdkU5bldjWkc2UkJycWZ3d1A4OHFYL1BYU29XMERDKyt4MDJtbE9JUGRFN001bjZmZFRNT0p3WVFqT3g3aDJnVUtQa25wQT0";
      this.AWSconnectID = "69d5653f-e608-4c55-8c59-755ece2bdec6";
      this.AWSAuthorizeURL =
        "https://pj6pjtxzw5yu4brhvthp6c4l340xljdv.lambda-url.us-east-1.on.aws";
    }
  }
}
export default new Config();
